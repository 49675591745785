import { Container, Row, Col, Tab } from "react-bootstrap";
import { ExperienceCard } from "./ExperienceCard";
import anosexperiencia_toolsinventor from "../assets/img/Icons/anosexperiencia_toolsinventor.png";
import proyectoscompletados_toolsinventor from "../assets/img/Icons/proyectoscompletados_toolsinventor.png";
import clientes_toolsinventor from "../assets/img/Icons/clientes_toolsinventor.png";
import 'animate.css';

export const Experience = () => {

  const projects = [
    {
      title: "10 años",
      imgUrl: anosexperiencia_toolsinventor,
    },
    {
      title: "60 proyectos",
      imgUrl: proyectoscompletados_toolsinventor,
    },
    {
      title: "40 empresas",
      imgUrl: clientes_toolsinventor,
    },
  ];

  return (
    <section className="experience" id="project">
      <Container>
        <Row>
          <Col size={12}>
              <div>
                <h2 className="titleblue">Experiencia</h2>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ExperienceCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ExperienceCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ExperienceCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
