import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import React from "react";
import Logo_tools_inventor_footer from '../assets/img/Logo_tools_inventor_footer.png';
import { Link } from "react-scroll";

export const Footer = () => {
  return (
<div className="footer mw-100">
        <footer className="text-white py-4 bg-dark footer">
          <div className="container">
            <nav className="row">
              <Link to='/' className="col-12 col-md-3 d-flex align-items-center justify-content-center">
                <img className="footer-img" src={Logo_tools_inventor_footer} alt="" /> 
              </Link>
              <ul className="col-12 col-md-3 list-unstyled">
                <li className="mb-4 titlefooter">Nuestra empresa</li>
                <a href="#home" className="subtitle-none"><li className="grow pfooter">Inicio</li></a>
                <a href="#clients" className="subtitle-none"><li className="grow pfooter">Clientes</li></a>
                <a href="#services" className="subtitle-none"><li className="grow pfooter">Servicios</li></a>
                <a href="#lenguajes" className="subtitle-none"><li className="grow pfooter">Tecnologías</li></a>
                <a href="#portafolio" className="subtitle-none"><li className="grow pfooter">Portafolio</li></a>
                <a href="#contact" className="subtitle-none"><li className="grow pfooter">Contácto</li></a>
              </ul>
              <ul className="col-12 col-md-3 list-unstyled">
                <li className="mb-4 titlefooter">Nuestros Servicios</li>
                <a href="#services" className="subtitle-none"><li className="grow pfooter">
                  <p>Diseño y Programación</p>
                </li></a>
                <a href="#services" className="subtitle-none"><li className="grow pfooter">
                  <p>Mantenimiento</p>
                </li></a>
                <a href="#services" className="subtitle-none"><li className="grow pfooter">
                  <p>Creatividad</p>
                </li></a>
                <a href="#services" className="subtitle-none"><li className="grow pfooter">
                  <p>Economico</p>
                </li></a>
                <a href="#services" className="subtitle-none"><li className="grow pfooter">
                  <p>Seguridad y Optimización</p>
                </li></a>
                <a href="#services" className="subtitle-none"><li className="grow pfooter">
                  <p>Marketing Digital</p>
                </li></a>
              </ul>
              <ul className="col-12 col-md-3 list-unstyled">
                <li className="mb-3 titlefooter">Contáctanos</li>
                <li className="p">
                  <a className="subtitle-none" href="https://www.google.com/maps/place/TOOL'S+INVENTOR+SI+TU+LO+PIENSAS+NOSOTROS+LO+PROGRAMAMOS/@4.598719,-74.0774982,15z/data=!4m5!3m4!1s0x0:0x639dee1574fbfbed!8m2!3d4.5987408!4d-74.0775326" target='_blank'><p><span className="texto">Ubicación:</span>  Carrera 9 # 10 - 19, Bogotá</p></a>
                </li>
                <li className="p">
                  <a className="subtitle-none" href="mailto:info@toolsinventor.com"><p><span className="texto">Email:</span>  info@toolsinventor.com</p></a>
                </li>
                <li className="p">
                  <a className="subtitle-none" href="tel:+573002454530"><p><span className="texto">Teléfono:</span>  +57 321 4297218</p></a>
                </li>
                <li className="d-flex justify-content-start">
                <div className="social-icon">
                {/* <a href="https://www.linkedin.com/in/divgital-colombia-bb019b254/" target={window}><img src={navIcon1} alt="" /></a> */}
                <a href="https://www.facebook.com/profile.php?id=100078121480881" target={window}><img src={navIcon2} alt="" /></a>
                {/* <a href="https://www.instagram.com/divgitalcolombia/" target={window}><img src={navIcon3} alt="" /></a> */}
              </div>
                </li>
              </ul>
              <div className="container">
                <p className="text-center text-footer">©Copyright 2020-2023 Tool's Inventor Todos los derechos reservados.</p>
                <a className="subtitle-none" target='_blank' href="https://www.divgital.com/"><p className="text-center">Developed by: Divgital </p></a>
              </div>
            </nav>
          </div>
        </footer>
    </div>
  );
};



