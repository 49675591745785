import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import sagasystem from "../assets/img/brands/sagasystem.png";
import globalgreens from "../assets/img/brands/globalgreens.png";
import latiendadealex from "../assets/img/brands/latiendadealex.png";
import sancarlos from "../assets/img/brands/sancarlos.png";
import lacasitashaddai from "../assets/img/brands/lacasitashaddai.png";
import sanjeronimo from "../assets/img/brands/sanjeronimo.png";
import individuo from "../assets/img/brands/individuo.png";
import Carousel from 'react-multi-carousel';
import uapic from "../assets/img/brands/uapic.png";
import tecnocell from "../assets/img/brands/tecnocell.png";
import benditochicharron from "../assets/img/brands/benditochicharron.png";
import once23 from "../assets/img/brands/once23.png";
import pablocarantonio from "../assets/img/brands/pablocarantonio.png";
import pollosfenix from "../assets/img/brands/pollosfenix.png";
import rampollo from  "../assets/img/brands/rampollo.png";
import technology from "../assets/img/brands/technology.png";
import technologythings from "../assets/img/brands/technologythings.png"
import 'react-multi-carousel/lib/styles.css';

export const Clients = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="portafolio" id="clients">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="wow zoomIn">
                        <h2 className="titleblue mb-5">Nuestros Clientes</h2>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme brands-slider">
                            <div className="item">
                                <img src={sagasystem} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={globalgreens} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={latiendadealex} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={sancarlos} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={lacasitashaddai} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={sanjeronimo} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={individuo} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={uapic} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={tecnocell} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={benditochicharron} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={once23} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={pablocarantonio} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={pollosfenix} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={technology} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={rampollo} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={technologythings} alt="Imagen" />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
