import { Container, Row, Col, Tab } from "react-bootstrap";
import { ServicesCard } from "./ServicesCard";
import disenoyprogramacion_toolsinventor from "../assets/img/Servicios/disenoyprogramacion_toolsinventor.png";
import mantenimiento_toolsinventor from "../assets/img/Servicios/mantenimiento_toolsinventor.png";
import creatividad_toolsinventor from "../assets/img/Servicios/creatividad_toolsinventor.png";
import economico_toolsinventor from "../assets/img/Servicios/economico_toolsinventor.png";
import seguridad_toolsinventor from "../assets/img/Servicios/seguridad_toolsinventor.png";
import marketingdigital_toolsinventor from "../assets/img/Servicios/marketingdigital_toolsinventor.png";


export const Services = () => {

  const projects = [
    {
      heading: "Diseño y Programación",
      detail: "Manejamos distintos paquetes de diseños que se ajustan a tus necesidades y requerimientos",
      imgUrl: disenoyprogramacion_toolsinventor,
    },
    {
      heading: "Mantenimiento",
      detail: "Brindamos mantenimiento a tus sitios web, actualización de tus catálogos y muchas más beneficios",
      imgUrl: mantenimiento_toolsinventor,
    },
    {
      heading: "Creatividad",
      detail: "En Tools´s Inventor contamos con un equipo especializado en diseño para darte un resultado unico",
      imgUrl: creatividad_toolsinventor,
    },
    {
      heading: "Económico",
      detail: "Tu economia es fundamental, por eso manejamos precios unicos y siempre apoyandote a crecer",
      imgUrl: economico_toolsinventor,
    },
    {
      heading: "Seguridad y Optimización",
      detail: "Analizaremos la velocidad de los servicios. Identificaremos vulnerabilidades y las solucionaremos",
      imgUrl: seguridad_toolsinventor,
    },
    {
      heading: "Marketing Digital",
      detail: "Digitaliza tu negocio y haz más fácil la vida de tus clientes, haremos que te busquen por ser la mejor opción",
      imgUrl: marketingdigital_toolsinventor,
    },
  ];

  return (
    <section className="project" id="services">
      <Container>
        <Row>
          <Col size={12}>
              <div className="project-bx">
                <h2 className="titlewhite">Nuestros Servicios</h2>
                <p className=" mt-4 mb-5">En tool's inventor podemos ayudarte a mejorar tu experiencia y la de tus clientes con nuestros servicios seguros y de calidad.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Tab.Content className="d-flex justify-content-center">
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ServicesCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ServicesCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ServicesCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
