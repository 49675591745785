import { Col } from "react-bootstrap";

export const ServicesCard = ({ heading, detail, imgUrl }) => {
  return (
    <Col className="container-service" size={12} sm={6} md={4}>
      <div className="row-service flip-card proj-imgbx">
         <div className="flip-card-inner">
           <div className="flip-card-front">
              <h1>{heading}</h1>
              <img src={imgUrl} alt="" />
            </div>
            <div className="flip-card-back">
               <h3>{detail}</h3>
            </div>
        </div>
      </div>
    </Col>
  )
}
