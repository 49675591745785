import { Container, Row, Col } from "react-bootstrap";
import tools_inventor_banner from "../assets/img/tools_inventor_banner.png"
import 'animate.css';

export const Banner = () => {

  return (
    <section className="banner" id="home">
      <Container className="banner-height">
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
              <div>
                <h1 className="titleblue">{`Creamos Software a tu Medida`}</h1>
                  <p>Somos una empresa comprometida a garantizar la satisfacción de nuestros clientes, brindamos asesoría en sitios web y software para ayudarte a hacer crecer tu negocio.  Contamos con personal experimentado y calificado para guiarte a escoger la mejor opción para posicionar tú marca e incursionar en el mundo tecnológico.</p>
              </div>
          </Col>
          <Col xs={12} md={6} xl={5}>
                <div className="animate__bounceInRight animate__animated animate__bounce animate__slow">
                  <img src={tools_inventor_banner} alt="Header Img"/>
                </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
