import SagaSystem from "../assets/img/portafolio/SagaSystem.PNG";
import SagaSystem2 from "../assets/img/portafolio/SagaSystem2.PNG";
import ToolsFinger from "../assets/img/portafolio/ToolsFinger.PNG";
import ToolsFinger2 from "../assets/img/portafolio/ToolsFinger2.PNG"
import VicentaGonzalez from "../assets/img/portafolio/VicentaGonzalez.jpeg";
import GilinStruments from "../assets/img/portafolio/GilinStruments.jpeg";
import GilinStruments2 from "../assets/img/portafolio/GilinStruments2.jpeg";
import ToolsPos from "../assets/img/portafolio/ToolsPos.jpeg";
import ToolsPos2 from "../assets/img/portafolio/ToolsPos2.jpeg";
import ToolsCalendar from "../assets/img/portafolio/ToolsCalendar.PNG";
import ToolsCalendar2 from "../assets/img/portafolio/ToolsCalendar2.PNG";
import Valientes from "../assets/img/portafolio/Valientes.png";
import Valientes2 from "../assets/img/portafolio/Valientes2.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const Portafolio = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="portafolio" id="portafolio">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="wow zoomIn">
                        <h2 className="titleblue">Portafolio</h2>
                        <p className="mt-1">Proyectos Destacados</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={SagaSystem} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={SagaSystem2} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={ToolsFinger} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={ToolsFinger2} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={VicentaGonzalez} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={GilinStruments} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={GilinStruments2} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={ToolsPos} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={ToolsPos2} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={ToolsCalendar} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={ToolsCalendar2} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={Valientes} alt="Imagen" />
                            </div>
                            <div className="item">
                                <img src={Valientes2} alt="Imagen" />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
