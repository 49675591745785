import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactanos_toolsinventor from "../assets/img/contactanos_toolsinventor.png"
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Enviar');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
      setFormDetails({
        ...formDetails,
        [category]: value
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Enviando...");
    var formdata = new FormData();
      formdata.append("nombre",formDetails.firstName);
      formdata.append("email",formDetails.email);
      formdata.append("telefono",formDetails.phone);
      formdata.append("mensaje",formDetails.message);
    let response = await fetch("https://toolsinventor.com/sendemailFormulario/send.php", {
      method: "POST",
      body: formdata,
    })
    .then(response => response.text())
     .then(result => {setStatus({ succes: true, message: 'Mensaje enviado'})
     setTimeout(() => {setStatus({})}, 5000)
    })
     .catch(error => {setStatus({ succes: false, message: 'Mensaje no enviado'})
     setTimeout(() => {setStatus({})}, 5000)
    });

    setButtonText("Enviar");  
    setFormDetails(formInitialDetails);
  };

  return (
    <section className="contact" id="contact">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img src={contactanos_toolsinventor} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div>
                <h2>Contácto</h2>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.firstName} placeholder="Nombre" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" value={formDetails.phone} placeholder="Teléfono" onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                    </Col>
                    <Col size={12} sm={12} className="px-1">
                      <input type="email" value={formDetails.email} placeholder="Email" onChange={(e) => onFormUpdate('email', e.target.value)} />
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" value={formDetails.message} placeholder="Mensaje" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                      <button className="button-co d-flex align-item-center" type="submit"><span>{buttonText}</span></button>
                    </Col>               
                  </Row>
                  {
                      status.message &&
                      <div class="alert alert-success mt-3" role="alert">
                          {status.message}
                      </div>
                      // <Col className="message-form">
                      //   <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      // </Col>
                      
                 }
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
