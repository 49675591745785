import React from "react";
import angular from "../assets/img/lenguajes/angular.png";
import php from "../assets/img/lenguajes/php.png";
import java from "../assets/img/lenguajes/java.png";
import csharp from "../assets/img/lenguajes/csharp.png";
import react from "../assets/img/lenguajes/react.png";
import mysql from "../assets/img/lenguajes/mysql.png";
import sqlserver from "../assets/img/lenguajes/sqlserver.png";
import postgresql from "../assets/img/lenguajes/postgresql.png";
import docker from "../assets/img/lenguajes/docker.png";
import firebase from "../assets/img/lenguajes/firebase.png";
import { motion } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Lenguajes = () => {

  return (
    <section className="project" id="lenguajes">
      <Container>
      <div> 
      <h2 className="titlewhite">Tecnologías</h2>
      <p className="pwhite">Desarrollamos aplicaciones de primer nivel, sin importar la plataforma que elijas.<br/>
          Nuestra experiencia en programación nos permite ofrecerte desarrollos en las<br/>
          principales plataformas, Windows, Linux, iOS, Android.
      </p>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
               <div className="w-right">
               <motion.div
                 initial={{ rotate: 45 }}
                 whileInView={{ rotate: 0 }}
                 viewport={{ margin: "-40px" }}
                 transition={{ duration: 3.5, type: "spring" }}
                 className="w-mainCircle"
               >
                 <div className="w-secCircle">
                   <img src={angular} alt="" />
                 </div>
                 <div className="w-secCircle">
                   <img src={java} alt="" />
                 </div>
                 <div className="w-secCircle">
                   <img src={php} alt="" />
                 </div>{" "}
                 <div className="w-secCircle">
                   <img src={csharp} alt="" />
                 </div>
                 <div className="w-secCircle">
                   <img src={react} alt="" />
                 </div>
               </motion.div>
               <div className="w-backCircle blueCircle"></div>
                <div className="w-backCircle yellowCircle"></div>
               </div>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
          <TrackVisibility>
              {({ isVisible }) =>
               <div className="w-right">
               <motion.div
                 initial={{ rotate: 45 }}
                 whileInView={{ rotate: 0 }}
                 viewport={{ margin: "-40px" }}
                 transition={{ duration: 3.5, type: "spring" }}
                 className="w-mainCircle"
               >
                 <div className="w-secCircle">
                   <img src={mysql} alt="" />
                 </div>
                 <div className="w-secCircle">
                   <img src={sqlserver} alt="" />
                 </div>
                 <div className="w-secCircle">
                   <img src={postgresql} alt="" />
                 </div>{" "}
                 <div className="w-secCircle">
                   <img src={docker} alt="" />
                 </div>
                 <div className="w-secCircle">
                   <img src={firebase} alt="" />
                 </div>
               </motion.div>
               <div className="w-backCircle blueCircle"></div>
                <div className="w-backCircle yellowCircle"></div>
               </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
        </div> 
      </Container>
    </section>
  )
}


