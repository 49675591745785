import { Col } from "react-bootstrap";

export const ExperienceCard = ({ title, imgUrl }) => {
  return (
    <Col className="container-service" size={12} sm={6} md={4}>
      <div className="row-service proj2-imgbx">
        <img src={imgUrl} alt="cardImg"/>
        <div className="proj2-txtx">
          <h4>{title}</h4>
        </div>
      </div>
    </Col>
  )
}
